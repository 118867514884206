import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export const LocusFileUploadIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.892 6.492L15.096 0.696C14.652 0.252 14.04 0 13.404 0H4.8C3.48 0 2.412 1.08 2.412 2.4L2.4 21.6C2.4 22.92 3.468 24 4.788 24H19.2C20.52 24 21.6 22.92 21.6 21.6V8.196C21.6 7.56 21.348 6.948 20.892 6.492ZM15.36 15.6H13.2V19.2C13.2 19.86 12.66 20.4 12 20.4C11.34 20.4 10.8 19.86 10.8 19.2V15.6H8.652C8.112 15.6 7.848 14.952 8.232 14.58L11.592 11.232C11.832 11.004 12.204 11.004 12.444 11.232L15.792 14.58C16.152 14.952 15.888 15.6 15.36 15.6ZM14.4 8.4C13.74 8.4 13.2 7.86 13.2 7.2V1.8L19.8 8.4H14.4Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
