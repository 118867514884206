export const FilterName = {
  CUST_OWNER: "custOwner",
  DATE_RANGE: "dateRange",
  FLOOR: "floor",
  INDUCT_AREA: "inductArea",
  OPEN_WORK_STATUS: "openWorkStatus",
  ORDER_TYPE: "orderType",
  ROTATION: "rotation",
  TASK_TYPE: "taskType",
  UNIT_OF_MEASURE: "unitOfMeasure",
  WORK_STATUS: "workStatus",
  WORK_AREA_GROUPING: "workAreaGrouping",
  ZONE: "zone",
};

export const DashboardQueryParam = {
  CUSTOWNER: "custowner",
  FLOOR: "floor",
  MEASURE: "measure",
  GROUPBY: "groupby",
  ROTATION: "rotation",
  TASKTYPE: "tasktype",
  ZONE: "zone",
};

export const FilterType = {
  SINGLE: "singleSelect",
  MULTIPLE: "multipleSelect",
  DATE_RANGE: "dateRangePicker",
  DATE_TIME_RANGE: "dateTimeRangePicker",
  TIME_RANGE: "timeRangePicker",
  TEXT_FIELD: "textField",
};

export const FilterDateRange = {
  LAST_1H: "last1h",
  LAST_6H: "last6h",
  TODAY: "today",
  LAST_7: "last7",
  MONTH_3: "month3",
  YEAR_2: "year2",
};

export const FilterValue = {
  ALL: "ALL",
};
