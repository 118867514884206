// third-party
import { createSlice } from "@reduxjs/toolkit";
import axios from "utils/axios";
import { dispatch } from "../index";

const flattenSiteActionLogs = (data) => {
  const flattenData = (item) => ({
    id: item.id,
    taskName: item.values?.taskType || "N/A",
    status: item?.values?.status || "N/A",
    taskCreatedAt: item.createdAt || "N/A",
    completedAt: item.completedAt || "N/A",
    updatedAt: item.updatedAt || "N/A",
    username: item.values?.user?.username || "N/A",
    taskId: item.values?.task || "N/A",
    scheduledAt: item?.values?.scheduledAt || "N/A",
    createdAt: item?.values?.createdAt,
    error: item?.values?.error || "N/A",
    arguments: item?.values?.arguments || "N/A",
  });
  return {
    data: data.logs?.rows.map(flattenData) || [],
    latestUpdate: data.latestUpdate,
  };
};

const flattenSiteActionOverview = (data) => {
  const flattenData = (item) => ({
    id: item.id,
    taskName: item.task?.name || "N/A",
    status: item.status,
    taskCreatedAt: item.scheduledAt || "N/A",
    scheduledAt: item.scheduledAt || "N/A",
    completedAt: item.completedAt || "N/A",
    updatedAt: item.task?.updatedAt || "N/A",
    username: item.user?.username || "N/A",
    taskId: item.task?.id || "N/A",
    error: item?.task?.error || "N/A",
    arguments: item?.arguments || "N/A",
  });
  return {
    currentQueue: data.currentQueue?.rows.map(flattenData) || [],
    recentlyCompleted: data.recentlyCompleted?.rows.map(flattenData) || [],
    latestUpdate: data.latestUpdate,
  };
};

// ----------------------------------------------------------------------

export const initialState = {
  error: null,
  tools: [],
  devices: [],
  selectedTool: {},
  robotsWithCarts: {},
  siteActionOverview: {
    currentQueue: [],
    recentlyCompleted: [],
    latestUpdate: {
      status: "New",
      updatedAt: "",
    },
  },
  siteActionLogs: {
    logs: {
      count: 0,
      rows: [],
      usingTest: false,
    },
    latestUpdate: {
      updatedAt: "",
      status: "New",
    },
  },
  integrationMonitoringLogs: { count: 0, rows: [] },
};

const slice = createSlice({
  name: "tool",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    getToolsSuccess(state, action) {
      state.tools = action.payload;
    },
    // SET SELECTED Tools
    getSelectedToolsSuccess(state, action) {
      state.selectedTool = action.payload;
    },
    getRobotsWithCartsSuccess(state, action) {
      state.robotsWithCarts = action.payload;
    },
    getSiteActionOverviewSuccess(state, action) {
      state.siteActionOverview = action.payload;
    },
    updateStatusSuccess(state, action) {
      state.siteActionOverview.latestUpdate.status = action.payload;
    },
    getSiteActionLogsSuccess(state, action) {
      state.siteActionLogs = action.payload;
    },
    getMerakiDevicesSuccess(state, action) {
      state.devices = action.payload;
    },
    getIntegrationMonitoringLogsSuccess(state, action) {
      state.integrationMonitoringLogs = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTools() {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/tools`,
        {},
        {},
      );
      dispatch(slice.actions.getSelectedToolsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRobotsWithCarts(site, page, pageSize) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/cart/robot`,
        {
          headers: {
            "locus-warehouse": site.condensedName,
          },
          params: {
            page,
            pageSize,
          },
        },
      );
      dispatch(slice.actions.getRobotsWithCartsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSiteActionLogs(
  site,
  environment,
  timestampRange,
  task = undefined,
  page,
  pageSize,
) {
  return async () => {
    const timestampRangeString =
      timestampRange[0] && timestampRange[1]
        ? `${new Date(timestampRange[0]).toISOString()},${new Date(timestampRange[1]).toISOString()}`
        : undefined;

    const response = await axios.get(
      `${process.env.REACT_APP_TOOLING_API}/tooling/siteaction/logs`,
      {
        headers: {
          "locus-agent-env": environment,
        },
        params: {
          site,
          timestampRange: timestampRangeString,
          group: false, // Todo maybe update
          page,
          pageSize,
          task,
        },
      },
    );

    const flattenedData = flattenSiteActionLogs(response.data.data);
    dispatch(slice.actions.getSiteActionLogsSuccess(flattenedData));
    return response;
  };
}

function buildStatusBody(
  site,
  user,
  taskType,
  args = [],
  scheduledAt = new Date(),
  status = "New",
) {
  let postBody = {
    scheduledAt: new Date(scheduledAt).toISOString(), // Ensure the date is in UTC format
    status,
    taskType,
    user: {
      username: user.username,
      id: user.id,
    },
    arguments: args,
    site: site.id,
  };

  switch (taskType) {
    case "ClearCart":
      postBody = {
        ...postBody,
        task: "636bf1338483ff4453b13472",
      };
      break;

    case "HealthCheck":
      postBody = {
        ...postBody,
        task: "636bf1338483ff4453b13475",
      };
      break;

    default:
      break;
  }

  return postBody;
}

export function postStatusAgentCheck(
  site,
  user,
  environment,
  taskType,
  args = [],
  scheduledAt = new Date(),
  status = "New",
) {
  const postBody = buildStatusBody(
    site,
    user,
    taskType,
    args,
    scheduledAt,
    status,
  );

  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TOOLING_API}/tooling/status/agenttask`,
        postBody, // Post body goes here
        {
          headers: {
            "locus-agent-env": environment,
          },
        },
      );
      dispatch(slice.actions.updateStatusSuccess("New"));
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  };
}

export function getSiteActionOverview(site, group, environment) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TOOLING_API}/tooling/siteaction/overview`,
        {
          headers: {
            "locus-agent-env": environment,
          },
          params: {
            site: site,
            group: group,
          },
        },
      );
      const flattenedData = flattenSiteActionOverview(response.data.data);
      dispatch(slice.actions.getSiteActionOverviewSuccess(flattenedData));
      return flattenedData;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      console.error(error);
    }
  };
}

export function getMerakiDevices(selectedSiteId) {
  return async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/mobiledevice/${selectedSiteId}`,
        {},
        {},
      );
      dispatch(slice.actions.getMerakiDevicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateMerakiDevice(context) {
  return async () => {
    try {
      return await axios.post(
        `${process.env.REACT_APP_SERVICES_API}/services/mobiledevicelockstate`,
        context,
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getIntegrationMonitoringLogs({
  pageNumber,
  pageSize,
  sortField,
  sortDirection,
  searchFilter,
  selectedClient,
  selectedSite,
  viewState,
}) {
  return async () => {
    try {
      let params = {
        page: pageNumber,
        pageSize: pageSize,
      };
      if (sortField) {
        params.sort = sortField;
      }
      if (sortDirection) {
        params.sortDirection = sortDirection.toUpperCase(); //Available values : ASC, DESC
      }
      if (viewState === "client" && selectedClient && selectedClient?.id) {
        params.client = selectedClient.id;
      }
      if (viewState === "site" && selectedSite && selectedSite?.id) {
        params.site = selectedSite.id;
      }

      params = { ...params, ...searchFilter };

      const response = await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/connectorlog`,
        {
          headers: {
            "locus-warehouse": selectedSite.condensedName,
          },
          params,
        },
        {},
      );
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess(response.data),
      );
    } catch (error) {
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess({
          count: 0,
          rows: [],
        }),
      );
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearIntegrationMonitoringLogs() {
  return async () => {
    try {
      dispatch(
        slice.actions.getIntegrationMonitoringLogsSuccess(
          initialState.integrationMonitoringLogs,
        ),
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIntegrationMonitoringLogById({ selectedSite, logId }) {
  return async () => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/connectorlog/${logId}`,
        {
          headers: {
            "locus-warehouse": selectedSite.condensedName,
          },
        },
        {},
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
