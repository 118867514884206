import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useConfig from "../../hooks/useConfig";
import { InfoRounded } from "@mui/icons-material";

// https://locusrobotics.atlassian.net/browse/HUB-2477
export const AnalyticsDisruptionBanner = () => {
  const theme = useTheme();
  const { navType } = useConfig();

  return (
    <Box
      id="globalAnalyticsDisruptionBanner"
      sx={{
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        color:
          navType === "dark"
            ? theme.palette.text.primary
            : theme.palette.text.primary,
      }}
      py={1}
      pr={1}
      mb={1}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "10px",
          marginLeft: "10px",
        }}
      >
        <InfoRounded stroke={1.5} size="20px" />
      </div>

      <Typography variant="subtitle1" color="inherit">
        Planned Maintenance: Sept. 12, 24 06:00 - 08:00 AM EST.
      </Typography>
    </Box>
  );
};
